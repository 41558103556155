import { TCurrencyKey } from "util/subscriptionUtils";

export interface Subscription {
  id: number;
  uuid?: string;
  userId: number;
  source: string;
  trialStartedAt: string | null;
  trialEndsAt: string | null;
  currentPeriodStartedAt: string;
  currentPeriodEndsAt: string;
  state: "active" | "cancelled" | "expired";
  postponeEndDate: string | null;
  postponedPeriod: string | null;
  postponedOn: string | null;
  canceledOn: string | null;
  expiresAt: string | null;
  provider: "google" | "stripe" | "apple";
  plan: Plan;
}
export interface SubscriptionGroup {
  id: number;
  industry: string;
  location: string;
  name: string;
}

export enum AccountType {
  Athlete = "athlete",
  Organization = "organization",
}

export interface Plan {
  id: number;
  code: string;
  cost?: string;
  currency?: TCurrencyKey;
  name: string;
  description: string | null;
  createdAt: string;
  updatedAt: string;
  trialIntervalLength: number;
  trialIntervalUnit: string;
  planIntervalLength: number;
  planIntervalUnit: string;
  amountInCents: AmountInCents;
  billingScheme?: string;
  tiers?: any;
}

export type TPlanCode =
  | "athlete-monthly"
  | "athlete-yearly"
  | "athlete-yearly-eur"
  | "athlete-monthly-eur"
  | "athlete-yearly-aud"
  | "athlete-monthly-aud"
  | "athlete-yearly-gbp"
  | "athlete-monthly-gbp";

export interface RwIntent {
  id: number;
  stripeId: string;
  clientSecret: string;
  type: "paymentIntent" | "setupIntent";
}

export interface StripePaymentIntent extends RwIntent {
  type: "setupIntent";
  plan: {
    id: number;
    name: string;
    code: string;
    amount: number;
  };
}

export interface StripeSetupIntent extends RwIntent {
  type: "setupIntent";
}

export interface AmountInCents {
  USD: number;
  EUR: number;
  AUD: number;
}

export enum SubRedirectParams {
  planId = "plan_id",
  planCode = "plan_code",
  accountType = "account_type",
  referralCode = "referral_code",
  promoCode = "campaign",
  paymentIntentClientSecret = "payment_intent_client_secret",
  setupIntentClientSecret = "setup_intent_client_secret",
  paymentIntent = "payment_intent",
  setupIntent = "setup_intent",
  redirectStatus = "redirect_status",
}

import { h, VNode, Fragment } from "preact";
import { useLayoutEffect, useState } from "preact/hooks";
import { Route, RouteProps } from "preact-router";

import User from "util/userUtil";
import { appRoute } from "util/routerUtil";
import { Toast } from "style/layout";
import Text from "components/shared/Text";
import ToastContext from "util/context/ToastContext";
import { useWindowSize } from "hooks/windowHook";
import MobileExperience from "components/shared/MobileExperience";
import { useAppProps } from "hooks/appPropHook";

interface ExtendedRouteProps<Props> extends RouteProps<Props> {
  isPrivate?: boolean;
  showMobile?: boolean;
  isOnboardingStep?: boolean;
  compProps?: Props;
}

function ExtendedRoute<Props>(props: ExtendedRouteProps<Props>): VNode {
  // const path = prefixPubPath(props.path);
  const { isPrivate, showMobile, isOnboardingStep, path, compProps } = props;
  const [message, setMessage] = useState("");
  const { width } = useWindowSize();

  // Authorize private pages only for logged users
  useLayoutEffect(() => {
    if (width > 0 && width <= 768 && !showMobile) {
      appRoute("/", true);
    }
    if (isPrivate && !User.isLoggedIn()) appRoute("/signup", true);
  }, [path, width]);

  // Authorize private pages only for users with subscription
  if (isPrivate && isOnboardingStep && !User.canAccessContent()) {
    appRoute("/signup/ath-payment", true);
  }
  const skipMobileAppNotice =
    typeof window !== "undefined" &&
    new URL(window.location.href)?.searchParams?.has("bypassmobile");
  const { mobileAppNotice } = useAppProps();

  const mobileBlocked =
    width <= 768 && !showMobile && mobileAppNotice && !skipMobileAppNotice;

  const routeProps = props as RouteProps<Props> & Partial<Props>;

  const triggerToast = (messageToast) => {
    setMessage(messageToast);
    setTimeout(() => {
      setMessage("");
    }, 1000);
  };

  return (
    <ToastContext.Provider value={{ triggerToast }}>
      <Fragment>
        <Route {...routeProps} {...compProps} />
        {mobileBlocked && (
          <MobileExperience showMobileExperience={mobileBlocked ?? false} />
        )}
        <Toast
          style={{
            display: message ? "flex" : "none",
            zIndex: message ? 3 : 0,
          }}
        >
          <Text size="M">{message}</Text>
        </Toast>
      </Fragment>
    </ToastContext.Provider>
  );
}

export default ExtendedRoute;

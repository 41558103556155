import { useSelector, useAction } from "@preact-hooks/unistore";
import actions from "store/actions";
import useUser from "hooks/userHook";

type OnboardingTipsViewedProps = {
  dashboard?: boolean;
  explore?: boolean;
  paths?: boolean;
  you?: boolean;
};

export enum TypeTips {
  NoTip = 0,
  DashboardTip = 1,
  DashboardIndividualTip = 1.1,
  ExploreTip = 2,
  ExploreIndividualTip = 2.1,
  PathsTip = 3,
  PathsIndividualTip = 3.1,
  YouTip = 4,
  YouIndividualTip = 4.1,
  NoIndividualTip = 5,
}

interface OnboardingTipHook {
  onboardingTipsViewed(): OnboardingTipsViewedProps;
  setOnboardingTip(tip: number): void;
  setOnboardingTipsViewed(tips: OnboardingTipsViewedProps): void;
  onboardingTip: number;
  onboardingTipDashboard: boolean | null;
  onboardingTipExplore: boolean | null;
  onboardingTipPaths: boolean | null;
  onboardingTipYou: boolean | null;
  firstSessionStart: boolean | null;
}

export function useOnboardingTip(): OnboardingTipHook {
  const { onboardingTip } = useSelector("onboardingTip");
  const setOnboardingTip = useAction(actions.setOnboardingTip);
  const { user } = useUser();
  const firstSessionStart = user && user?.signInCount < 2;

  const onboardingTipDashboard =
    (onboardingTip === TypeTips.DashboardTip ||
      onboardingTip === TypeTips.DashboardIndividualTip) &&
    firstSessionStart;
  const onboardingTipExplore =
    (onboardingTip === TypeTips.ExploreTip ||
      onboardingTip === TypeTips.ExploreIndividualTip) &&
    firstSessionStart;
  const onboardingTipPaths =
    (onboardingTip === TypeTips.PathsTip ||
      onboardingTip === TypeTips.PathsIndividualTip) &&
    firstSessionStart;
  const onboardingTipYou =
    (onboardingTip === TypeTips.YouTip ||
      onboardingTip === TypeTips.YouIndividualTip) &&
    firstSessionStart;

  const onboardingTipsViewed = () => {
    if (typeof window !== "undefined") {
      try {
        const tipsViewed = localStorage?.getItem("onboardingTipsViewed");
        return tipsViewed ? JSON.parse(tipsViewed) : "";
      } catch (err) {
        console.error(err);
      }
    }
  };

  const setOnboardingTipsViewed = (tips: OnboardingTipsViewedProps) => {
    const { dashboard, explore, paths, you } = tips || {};
    if (typeof window !== "undefined") {
      localStorage.setItem(
        `onboardingTipsViewed`,
        JSON.stringify({
          dashboard,
          explore,
          paths,
          you,
        })
      );
    }
  };

  return {
    onboardingTipsViewed,
    setOnboardingTip,
    setOnboardingTipsViewed,
    onboardingTip,
    onboardingTipDashboard,
    onboardingTipExplore,
    onboardingTipPaths,
    onboardingTipYou,
    firstSessionStart,
  };
}

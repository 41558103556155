import { API_BASE, APIResponse, callApi } from "./api";
import { Path, UserPath } from "models/path";

export async function getPaths(featured = false): Promise<APIResponse<Path[]>> {
  try {
    const resp = await callApi(
      `${API_BASE}/paths${featured ? "?featured=true" : ""}`
    );
    return resp;
  } catch (e) {
    throw e;
  }
}

export async function getPath(pathId: number): Promise<APIResponse<Path>> {
  try {
    const resp = await callApi(`${API_BASE}/paths/${pathId}`);
    return resp;
  } catch (e) {
    throw e;
  }
}

// List all paths that have been started, in progress, or completed for the current user
export async function getUserPaths(): Promise<APIResponse<UserPath>> {
  try {
    const resp = await callApi(`${API_BASE}/user_paths`);
    return resp;
  } catch (e) {
    throw e;
  }
}

// Join path
export async function addPath(opts: {
  pathId: number;
}): Promise<APIResponse<UserPath>> {
  try {
    const resp = await callApi(`${API_BASE}/user_paths`, {
      method: "POST",
      body: opts,
    });
    return resp;
  } catch (e) {
    throw e;
  }
}

// Reset path
export async function resetPath(opts: {
  pathId: number;
}): Promise<APIResponse<UserPath>> {
  try {
    const resp = await callApi(`${API_BASE}/user_paths/${opts.pathId}/start`, {
      method: "PUT",
    });
    return resp;
  } catch (e) {
    throw e;
  }
}

// Leave path
export async function leavePath(opts: {
  pathId: number;
}): Promise<APIResponse<UserPath>> {
  try {
    const resp = await callApi(`${API_BASE}/user_paths/${opts.pathId}`, {
      method: "DELETE",
    });
    return resp;
  } catch (e) {
    throw e;
  }
}

// Next video in the path
export async function advancePath(opts: {
  pathId: number;
}): Promise<APIResponse<UserPath>> {
  try {
    const resp = await callApi(`${API_BASE}/user_paths/${opts.pathId}/next`, {
      method: "PUT",
    });
    return resp;
  } catch (e) {
    throw e;
  }
}

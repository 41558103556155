import { useAction, useSelector } from "@preact-hooks/unistore";
import { useEffect } from "preact/hooks";

import actions from "store/actions";
import { getPaths, getPath } from "services";
import {
  getUserPaths,
  addPath as _addPath,
  resetPath as _resetPath,
  leavePath as _leavePath,
  advancePath as _advancePath,
} from "services/paths";
import { Path, UserPath } from "models/path";
import useUser from "./userHook";
import { BoundAction } from "unistore";

interface PathsHook {
  paths: Path[] | null;
}

let fetchingPaths = false;

export function usePaths(): PathsHook {
  const setPathsAction = useAction(actions.setPaths);
  const { paths } = useSelector("paths");
  useEffect(() => {
    if (!fetchingPaths && !paths) {
      fetchingPaths = true;
      (async () => {
        try {
          const result = await getPaths();
          setPathsAction(result.data);
        } catch (error) {
          console.error("ERROR", error);
        }
      })();
    }
  }, []);
  return { paths };
}

export function useWelcomePath(pathId: number | null): { welcomePath: Path } {
  const setWelcomePathsAction = useAction(actions.setWelcomePath);
  const { welcomePath } = useSelector("welcomePath");
  useEffect(() => {
    if (pathId && !fetchingPaths && !welcomePath) {
      fetchingPaths = true;
      (async () => {
        try {
          const result = await getPath(pathId);
          setWelcomePathsAction(result.data);
        } catch (error) {
          console.error("ERROR", error);
        }
      })();
    }
  }, [pathId]);
  return { welcomePath };
}

interface FeaturedPathsHook {
  featuredPaths: Path[] | null;
}

let fetchingFeaturedPaths = false;

export function useFeaturedPaths(): FeaturedPathsHook {
  const setFeaturedPathsAction = useAction(actions.setFeaturedPaths);
  const { featuredPaths } = useSelector("featuredPaths");
  useEffect(() => {
    if (!fetchingFeaturedPaths && !featuredPaths) {
      fetchingFeaturedPaths = true;
      (async () => {
        try {
          const result = await getPaths(true);
          setFeaturedPathsAction(result.data);
        } catch (error) {
          console.error("ERROR", error);
        }
      })();
    }
  }, []);
  return { featuredPaths };
}

interface UserPathsHook {
  userPaths?: UserPath[] | null;
  addPath?(pathId: number): Promise<void>;
  resetPath?(pathId: number): Promise<void>;
  leavePath?(pathId: number): Promise<void>;
  startPath?(pathId: number): void;
  advancePath?(pathId: number): Promise<void>;
}

let fetchingUserPaths = false;

export function useUserPaths(): UserPathsHook {
  const setUserPathsAction = useAction(actions.setUserPaths);
  const addPathAction = useAction(actions.addPath);
  const resetPathAction = useAction(actions.resetPath);
  const leavePathAction = useAction(actions.leavePath);
  const advancePathAction = useAction(actions.advancePath);
  const startPathAction = useAction(actions.startPath);
  const { userPaths } = useSelector("userPaths");
  const { user, errorRedirect } = useUser();
  const userPathsMethods = {
    addPath: (pathId: number) => addPath(addPathAction, pathId),
    resetPath: (pathId: number) => resetPath(resetPathAction, pathId),
    leavePath: (pathId: number) => leavePath(leavePathAction, pathId),
    advancePath: (pathId: number) => advancePath(advancePathAction, pathId),
    startPath: (pathId: number) => startPath(startPathAction, pathId),
  };

  if (!user) return { userPaths: null };

  useEffect(() => {
    if (!fetchingUserPaths && !Array.isArray(userPaths)) {
      fetchingUserPaths = true;
      (async () => {
        try {
          const result = await getUserPaths();
          setUserPathsAction(result.data);
          fetchingUserPaths = false;
        } catch (error) {
          console.error("ERROR", error);
          errorRedirect(error);
        }
      })();
    }
  }, []);

  const addPath = async (
    addPathAction: BoundAction,
    pathId: number
  ): Promise<void> => {
    try {
      if (!user) throw new Error("No User Logged in to add path");
      const result = await _addPath({ pathId });
      addPathAction(result.data);
    } catch (error) {
      console.error("ERROR", error);
      errorRedirect(error);
    }
  };

  const resetPath = async (
    resetPathAction: BoundAction,
    pathId: number
  ): Promise<void> => {
    try {
      if (!user) throw new Error("No User Logged in to reset path");
      const result = await _resetPath({ pathId });
      resetPathAction(result.data);
    } catch (error) {
      console.error("ERROR", error);
      errorRedirect(error);
    }
  };

  const leavePath = async (
    leavePathAction: BoundAction,
    pathId: number
  ): Promise<void> => {
    try {
      if (!user) throw new Error("No User Logged in to leave path");
      await _leavePath({ pathId });
      leavePathAction(pathId);
    } catch (error) {
      console.error("ERROR", error);
      errorRedirect(error);
    }
  };

  const advancePath = async (
    advancePathAction: BoundAction,
    pathId: number
  ): Promise<void> => {
    try {
      if (!user) throw new Error("No User Logged in to advance path");
      const result = await _advancePath({ pathId });
      await advancePathAction(result.data);
    } catch (error) {
      console.error("ERROR", error);
      errorRedirect(error);
    }
  };

  const startPath = (startPathAction: BoundAction, pathId: number): void => {
    try {
      if (!user) throw new Error("No User Logged in to advance path");
      startPathAction(pathId);
    } catch (error) {
      console.error("ERROR", error);
      errorRedirect(error);
    }
  };

  return { userPaths, ...userPathsMethods };
}

import { pubPathNoTrailingSlash } from "config";
import { h, FunctionalComponent, ComponentChild } from "preact";
import { Router as DefRouter, RouterProps } from "preact-router";
import { prefixPubPath } from "util/routerUtil";

interface LocalRouterProps extends RouterProps {
  children?: ComponentChild[];
}

const Router: FunctionalComponent<LocalRouterProps> = (props): preact.VNode => {
  const children = props?.children?.map((c) => {
    if (c && (c as any)?.props?.path) {
      (c as any).props.path = prefixPubPath((c as any).props.path);
    }
    return c;
  });
  return <DefRouter {...props}>{children}</DefRouter>;
};

export default Router;

import { h, FunctionalComponent, Fragment } from "preact";
import { styled } from "linaria/react";
import Colors from "style/colors";
import { appRoute } from "util/routerUtil";
import { useFeaturedPaths, useWelcomePath } from "hooks/pathHook";
import ProfileMenu from "components/layout/ProfileMenu";
import ConfirmModal from "components/shared/ConfirmModal";
import { useEffect, useState } from "preact/hooks";
import { copyToClipboard } from "util/videoUtil";
import { useContext } from "preact/hooks";
import ToastContext from "util/context/ToastContext";
import { useBranch } from "hooks/branchHook";
import useUser from "hooks/userHook";
import { useSingleRoutine } from "hooks/videoHook";
import { useSchedule, useVideoOfDay } from "hooks/scheduleHook";
import UserUtil from "util/userUtil";
import { useCurrentUrl } from "hooks/currentUrlHook";
import { pubPath } from "config";
import { Video } from "models/video";
import { Path } from "models/path";

const WELCOME_PATH_ID = 12;

const Container = styled.div<{ user: any; showMobileExperience: boolean }>`
  display: ${({ showMobileExperience }) =>
    showMobileExperience ? "block" : "none"};
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  z-index: 1000;
  > div:last-child {
    position: absolute;
    max-width: 100%;
    top: auto;
    height: auto;
    min-height: 162px;
    border-radius: 0;
    z-index: 2;
    box-shadow: none;

    > div:nth-child(1) {
      margin-bottom: 14px;
      max-width: ${({ user }) => (user ? "100%" : "345px")};

      span {
        font-weight: ${({ user }) => (user ? "600" : "500")};
        font-size: ${({ user }) => (user ? "20px" : "16px")};
        line-height: ${({ user }) => (user ? "26px" : "20px")};
        color: ${Colors.txtDark};
      }
    }
    > div:nth-child(2) > div {
      margin-bottom: 0 !important;
      background: #f9e643;
      border-radius: 8px;
      padding: 16px 24px !important;
      > span {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: ${Colors.txtDark};
      }
    }
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  padding: 0 16px;

  > div:nth-child(2) {
    > div {
      margin: 0;
      background: ${Colors.bgGrey0};
      height: 1.8rem;
      width: 1.8rem;
      > div:nth-child(1) {
        > div {
          border: 1px solid #000000;
          height: 24px;
          width: 24px;
        }
      }
      > div:nth-child(2) {
        z-index: 1000;
        top: 68px;
        bottom: initial;

        > div:nth-child(1) > div {
          max-width: 185px;
          h1 {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      > div:nth-child(3) {
        background: ${Colors.bgDark0};
        opacity: 0.6;
      }
    }
  }
  button {
    border: 0;
    padding: 0;
    cursor: pointer;
    margin: 0;
  }
`;

export const MainContent = styled.div<{ type: string }>`
  > div {
    width: 100%;
    margin-bottom: 16px;

    > img {
      width: 100%;
      height: 260px;
      object-fit: cover;
    }
    > h2 {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      color: ${Colors.txtDark1};
      text-transform: initial;
      margin-left: 16px;
    }
  }
`;

export const Content = styled.div<{ type?: string }>`
  width: 100%;
  margin-bottom: 16px;

  > div:nth-child(1) {
    position: relative;
    > img {
      width: 100%;
      height: ${({ type }) => (type === "routine" ? "312px" : "260px")};
      object-fit: cover;
    }
    h1 {
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      color: ${Colors.txtLight};
      text-transform: initial;
      position: absolute;
      bottom: 24px;
      left: 16px;
      max-width: 260px;
    }
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  > div:nth-child(2) {
    padding: 0 16px;
    border-bottom: 1px solid #8e8e93;
    height: 58px;
    background: ${Colors.bgLight1};

    h2 {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      color: ${Colors.txtDark1};
      text-transform: initial;
    }

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: ${Colors.txtDark1};
    }
  }

  > div:nth-child(3) {
    justify-content: initial;
    min-height: 48px;
    padding: 0 16px;
    background: ${Colors.bgLight1};

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: ${Colors.txtDark1};
    }
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.txtDark1};
    padding: 8px 16px 16px 16px;
    background: ${Colors.bgLight1};
  }
`;

export const Skeleton = styled.div`
  > div {
    height: 302px;
    width: 100%;
    margin-bottom: 32px;
    background-color: ${Colors.bgLight3};
  }
`;

interface MobileExperienceProps {
  showMobileExperience: boolean;
}

interface MobileShareLink {
  deepLink: string;
}

export function useMobileShareLink(
  type: "home" | "path" | "routine"
): MobileShareLink {
  const { shareDeepLink } = useBranch();
  const [deepLink, setDeepLink] = useState<string>("");
  const { user } = useUser();
  const { videoOfDay } = useVideoOfDay(["home", "routine"].includes(type));
  const { welcomePath } = useWelcomePath(
    type == "path" ? WELCOME_PATH_ID : null
  );

  let thumbnailUrl = "";
  let pageData: Video | Path | null = null;

  if (type === "path") {
    thumbnailUrl = welcomePath?.mobileHeroImageMetadata?.url;
    pageData = welcomePath;
  } else {
    thumbnailUrl = videoOfDay?.thumbnail?.url || "";
    pageData = videoOfDay;
  }

  useEffect(() => {
    const getDeepLink = (thumbnailUrl: string, pageData: any) => {
      if (!pageData) return "";
      const { id, slug, title, description } = pageData;
      const deepLink = shareDeepLink(
        { id, slug, title, description, thumbnailUrl },
        type
      );

      return deepLink;
    };
    setDeepLink(getDeepLink(thumbnailUrl, pageData));
    if (
      !user &&
      typeof window !== "undefined" &&
      localStorage.getItem("deepLink")
    ) {
      appRoute("/signin");
    }
  }, [type]);

  return { deepLink };
}

const MobileExperience: FunctionalComponent<MobileExperienceProps> = ({
  showMobileExperience,
}: MobileExperienceProps) => {
  const { triggerToast } = useContext(ToastContext);

  const { currentURL } = useCurrentUrl();
  const { pathname: path } = currentURL;
  const isPath = /path/.test(path ?? "");
  const isHome = path === "/" || path === pubPath;

  const { user } = useUser();
  const type = isHome ? "home" : isPath ? "path" : "routine";
  const { deepLink } = useMobileShareLink(type);

  const activeSubscription = user && user?.subscription?.state === "active";
  const noSubscription = user && !user?.subscription;
  const expiredSubscription = user && user?.subscription?.state === "expired";
  const accountType =
    user && UserUtil.isActiveGroupOwner(user) ? "org-payment" : "ath-payment";

  const handleCopyToClipboard = async () => {
    if (!deepLink) return;
    try {
      copyToClipboard(deepLink);
      triggerToast("Copied to clipboard");
    } catch (error) {
      console.error("ERROR", error);
    }
  };

  const getDownloadAppUrl = () => {
    if (typeof window !== "undefined") {
      const isIOS = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent);
      if (isIOS) {
        window.open("//apps.apple.com/us/app/romwod/id1175346453");
      } else {
        window.open(
          "//play.google.com/store/apps/details?id=com.romwodllc.android"
        );
      }
    }
  };

  return (
    <Container
      user={activeSubscription}
      showMobileExperience={showMobileExperience}
    >
      <Header>
        <div>
          <img
            src={`https://optimize.romwod.com/image/upload/v1652723925/app/logo-black.png`}
            alt="pliability Logo Mark"
            width={22}
            height={15}
            style={{ marginRight: "5px" }}
          />
          <img
            src="https://optimize.romwod.com/image/upload/v1657668183/app/wordmark-pliability.png"
            alt="wordmark pliability"
            width={56}
            height={13}
          />
        </div>
        <div>
          {isHome && user ? (
            <ProfileMenu />
          ) : isHome && !user ? null : (
            <button>
              <img
                src="https://optimize.romwod.com/image/upload/v1659637944/app/share-icon-dark.png"
                alt="share icon"
                width={24}
                height={24}
                onClick={() => handleCopyToClipboard()}
              />
            </button>
          )}
        </div>
      </Header>
      <MainContent type={type}>
        {isHome ? (
          <MobileHomeExperience />
        ) : isPath ? (
          <MobilePathExperience />
        ) : (
          <MobileRoutineExperience type={type} />
        )}
      </MainContent>
      <ConfirmModal
        question={
          activeSubscription
            ? "Start your mobility journey in our iOS and Android apps."
            : `Better movement and heightened performance with evidence-based mobility sessions. ${
                !expiredSubscription ? "Try free for 7 days, " : ""
              }${!expiredSubscription ? "c" : "C"}ancel anytime.`
        }
        confirm={{
          text: activeSubscription
            ? "Open the app"
            : expiredSubscription
            ? "Join Now"
            : "Get 7 Days Free",
          onConfirm: () => {
            activeSubscription
              ? getDownloadAppUrl()
              : expiredSubscription || (user && !noSubscription)
              ? appRoute(`/signup/${accountType}`, true)
              : appRoute(`/signup`, true);
          },
        }}
      />
    </Container>
  );
};

const MobileRoutineExperience = ({ type }) => {
  const { currentVideo } = useSingleRoutine();
  if (!currentVideo)
    return (
      <Skeleton>
        <div />
      </Skeleton>
    );

  const { durationInSeconds } = currentVideo || {};
  const pad = (d) => (d < 10 ? "0" + d.toString() : d.toString());
  const minutes = durationInSeconds && pad(Math.floor(durationInSeconds / 60));

  return (
    <Content type={type}>
      <div>
        <img
          src={`${currentVideo?.thumbnail?.url}`}
          alt="wordmark pliability"
          width={56}
          height={13}
        />
        <h1>{currentVideo.title}</h1>
      </div>
      <div>
        <h2>Overview</h2>
        <div>
          <img
            src="https://optimize.romwod.com/image/upload/v1658232306/app/timer-icon.png"
            alt="timer icon"
            width={24}
            height={24}
            style={{ marginRight: "12px" }}
          />
          <span style={{ marginTop: "2px" }}>{minutes}min</span>
        </div>
      </div>
      <div>
        <img
          src="https://optimize.romwod.com/image/upload/v1658232300/app/person-icon.png"
          alt="person icon"
          width={24}
          height={24}
          style={{ marginRight: "12px" }}
        />
        <div>
          {currentVideo?.targetAreas?.map(
            ({ content }, index) =>
              index <= 2 && (
                <span key={index} style={{ marginRight: "4px" }}>
                  {` ${index !== 0 && index !== 5 ? " •" : ""} ${content} `}
                </span>
              )
          )}
        </div>
      </div>
    </Content>
  );
};

const MobileHomeExperience: FunctionalComponent = ({}) => {
  const { featuredPaths } = useFeaturedPaths();
  if (!featuredPaths)
    return (
      <Skeleton>
        <div></div>
      </Skeleton>
    );

  return (
    <Fragment>
      {featuredPaths?.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            item.slug === "welcome-series" &&
              appRoute(`/path/${item.slug}`, true);
          }}
        >
          <img src={item?.images?.mobileHero || ""} alt="" />
          <h2>{item.title} Path</h2>
        </div>
      ))}
    </Fragment>
  );
};

const MobilePathExperience: FunctionalComponent = ({}) => {
  const { welcomePath } = useWelcomePath(WELCOME_PATH_ID);
  const { currentWorkout } = useSchedule();
  const { setCurrentVideo } = useSingleRoutine(null);
  if (!welcomePath)
    return (
      <Skeleton>
        <div />
      </Skeleton>
    );

  return (
    <Content
      onClick={() => {
        appRoute(`/routine/${currentWorkout?.video?.slug}`, true);
        setCurrentVideo(currentWorkout?.video);
      }}
    >
      <div>
        <img
          src={`${welcomePath?.mobileHeroImageMetadata.url}`}
          alt="wordmark pliability"
          width={56}
          height={13}
        />
        <h1>{welcomePath.title} Path</h1>
      </div>
      <div>
        <h2>Overview</h2>
        <div>
          <img
            src="https://optimize.romwod.com/image/upload/v1659637949/app/video-icon-dark.png"
            alt="video icon"
            width={24}
            height={24}
            style={{ marginRight: "12px" }}
          />
          <span>{welcomePath.pathVideos.length} routines</span>
        </div>
      </div>
      <p>
        The perfect place to start you Pliability journey. Get to know the
        poses, the purpose, and the foundation of our program as you spend time
        each day moving through these routines.
      </p>
    </Content>
  );
};

export default MobileExperience;

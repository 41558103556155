import { h, FunctionalComponent } from "preact";
import { styled } from "linaria/react";
import { useEffect } from "preact/hooks";

import Colors from "style/colors";
import mq from "style/breakpoints";
import { DisplayOverlay } from "style/layout";
import Icon, { IconTypes } from "components/shared/Icon";
import { useWindowSize } from "hooks/windowHook";
import { WelcomeModalStep } from "models/welcomeModal";

const Container = styled.div<{
  mobileDownloadApp: boolean;
  pricingOrg: boolean;
  detailsOrg: boolean;
  survey: boolean;
  width: number;
  headerTitle: string | undefined;
  modalMember: boolean;
  accountType: string | undefined;
  modalOrg: number | undefined;
  modalPath: any;
  notificationPermissionModal: any;
}>`
  width: ${({ mobileDownloadApp }) => (mobileDownloadApp ? "100vw" : "100%")};
  height: ${({
    mobileDownloadApp,
    detailsOrg,
    survey,
    width,
    notificationPermissionModal,
  }) =>
    mobileDownloadApp
      ? "100vh"
      : (detailsOrg && width < 500) ||
        (survey && width < 500) ||
        notificationPermissionModal < 500
      ? "auto"
      : "min-content"};
  max-width: ${({ pricingOrg }) => (pricingOrg ? "min-content" : "none")};
  background-color: ${Colors.bgLight2};
  overflow-x: hidden;
  position: fixed;
  margin: ${({ mobileDownloadApp }) => (mobileDownloadApp ? "0" : "auto")};
  border: ${({ pricingOrg }) => (pricingOrg ? "1px solid #2E2E2E" : "none")};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  ${mq.minXs} {
    width: ${({ mobileDownloadApp }) =>
      mobileDownloadApp ? "32.75rem" : "100%"};
    max-width: ${({
      pricingOrg,
      detailsOrg,
      modalMember,
      accountType,
      modalPath,
      notificationPermissionModal,
      modalOrg,
    }) =>
      modalMember
        ? "460px"
        : pricingOrg
        ? "512px"
        : modalPath
        ? "439px"
        : detailsOrg && modalOrg === WelcomeModalStep.successRegistration
        ? "1054px"
        : detailsOrg
        ? "512px"
        : notificationPermissionModal
        ? "641px"
        : "none"};
    height: ${({ mobileDownloadApp }) =>
      mobileDownloadApp ? "41.25rem" : "min-content"};
    margin: auto;
    position: ${({ pricingOrg }) => (pricingOrg ? "relative" : "fixed")};
    border-radius: ${({ pricingOrg, modalPath, notificationPermissionModal }) =>
      pricingOrg || modalPath || notificationPermissionModal
        ? "0.75rem"
        : "none"};
    left: ${({ modalPath }) => (modalPath ? "96px" : "0")};
  }

  ${mq.sm} {
    max-width: ${({
      pricingOrg,
      detailsOrg,
      modalMember,
      accountType,
      modalPath,
      notificationPermissionModal,
    }) =>
      modalMember
        ? "460px"
        : pricingOrg
        ? "512px"
        : modalPath
        ? "360px"
        : detailsOrg && accountType === "athlete"
        ? "700px"
        : detailsOrg
        ? "512px"
        : notificationPermissionModal
        ? "641px"
        : "none"};
    left: 0;
  }

  ${mq.md} {
    max-width: ${({
      pricingOrg,
      detailsOrg,
      modalMember,
      accountType,
      modalPath,
      notificationPermissionModal,
    }) =>
      modalMember
        ? "460px"
        : pricingOrg
        ? "512px"
        : modalPath
        ? "439px"
        : detailsOrg && accountType === "athlete"
        ? "900px"
        : detailsOrg
        ? "512px"
        : notificationPermissionModal
        ? "641px"
        : "none"};
  }

  ${DisplayOverlay} {
    background-color: ${Colors.bgDark0};
    opacity: ${({
      detailsOrg,
      survey,
      modalMember,
      modalPath,
      width,
      notificationPermissionModal,
      accountType,
    }) =>
      (detailsOrg && accountType === "athlete") ||
      survey ||
      notificationPermissionModal ||
      (modalPath && width < 500)
        ? "0"
        : modalPath
        ? "0.4"
        : "0.7"};
    z-index: ${({
      pricingOrg,
      modalMember,
      mobileDownloadApp,
      modalPath,
      notificationPermissionModal,
      accountType,
      detailsOrg,
    }) =>
      (detailsOrg && accountType !== "athlete") ||
      pricingOrg ||
      modalMember ||
      mobileDownloadApp ||
      modalPath ||
      notificationPermissionModal
        ? "-1"
        : "none"};
  }
`;

const Header = styled.div<{
  type;
  pricingOrg;
  headerPadding;
  headerTitle: string | undefined;
  modalMember: boolean;
  accountType?: string | undefined;
  survey?: boolean;
  modalOrg?: number;
}>`
  position: ${({ pricingOrg, modalOrg }) =>
    pricingOrg ? "fixed" : modalOrg === 3 ? "absolute" : "relative"};
  top: ${({ pricingOrg }) => (pricingOrg ? "25px" : "none")};
  right: ${({ pricingOrg }) => (pricingOrg ? "16px" : "none")};
  background: ${({ modalMember }) =>
    modalMember ? Colors.bgDark4 : "inherit"};
  display: flex;
  justify-content: ${({ headerTitle, survey }) =>
    headerTitle ? "space-between" : survey ? "initial" : "flex-end"};
  z-index: 4;
  padding: ${({ headerPadding }) => (headerPadding ? headerPadding : "")};
  width: 100%;
  ${mq.xs} {
    position: ${({ headerPadding, survey }) =>
      headerPadding || survey ? "initial" : "fixed"};
    right: ${({ pricingOrg }) => (pricingOrg ? "none" : "0")};
    max-width: ${({ modalMember }) => (modalMember ? "none" : "343px")};
  }
  > p {
    height: 22px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    color: #ffffff;
  }
`;

const Close = styled.div<{
  mobileDownloadApp;
  modalMember: boolean | undefined;
  survey: boolean;
  modalOrg?: number;
}>`
  padding: ${({ mobileDownloadApp, survey, modalOrg }) =>
    mobileDownloadApp
      ? "2rem 2.5rem 0 0"
      : survey
      ? "16px"
      : modalOrg === WelcomeModalStep.successRegistration
      ? "30px 30px 0 0"
      : "0"};
  span {
    color: ${({ modalMember }) =>
      modalMember ? Colors.txtLight : Colors.txtDark};
    font-size: ${({ survey, modalOrg }) =>
      survey
        ? "20px"
        : modalOrg === WelcomeModalStep.successRegistration
        ? "14px"
        : "inherit"};
    cursor: pointer;
  }
`;

export interface ModalProps {
  children: JSX.Element | JSX.Element[] | string | number;
  isVisible: boolean;
  onClose?: (e) => void;
  closeIcon?: boolean;
  overlay?: boolean;
  type?: string;
  headerPadding?: string;
  headerTitle?: string;
  headerIcon?: any;
  accountType?: string;
  modalOrg?: number;
}

const Modal: FunctionalComponent<ModalProps> = ({
  children,
  isVisible,
  onClose,
  closeIcon,
  overlay,
  type,
  headerPadding,
  headerTitle,
  headerIcon,
  accountType,
  modalOrg,
}: ModalProps) => {
  useEffect(() => {
    document.body.classList.toggle("modal-open", isVisible);
    document.body.classList.toggle(`modal-open-${type}`, isVisible);
  }, [isVisible]);

  if (!isVisible) return null;
  const { X } = IconTypes;
  const { width } = useWindowSize();
  const pricingOrg = type === "pricingOrg";
  const mobileDownloadApp = type === "mobileDownloadApp";
  const detailsOrg = type === "detailsOrg";
  const survey = type === "survey";
  const modalMember = type === "modalMember";
  const modalPath = type === "modalPath";
  const notificationPermissionModal = type === "notificationPermissionModal";

  return (
    <Container
      width={width}
      mobileDownloadApp={mobileDownloadApp}
      pricingOrg={pricingOrg}
      detailsOrg={detailsOrg}
      survey={survey}
      headerTitle={headerTitle}
      modalMember={modalMember}
      accountType={accountType}
      modalOrg={modalOrg}
      modalPath={modalPath}
      notificationPermissionModal={notificationPermissionModal}
    >
      {closeIcon && (
        <Header
          type={type}
          pricingOrg={pricingOrg}
          headerPadding={headerPadding}
          headerTitle={headerTitle}
          modalMember={modalMember}
          survey={survey}
          modalOrg={modalOrg}
        >
          {headerTitle && <p>{headerTitle}</p>}
          <Close
            mobileDownloadApp={mobileDownloadApp}
            modalMember={modalMember}
            onClick={onClose}
            survey={survey}
            data-testid="iconCloseModal"
            modalOrg={modalOrg}
          >
            <Icon name={X} size={headerIcon} />
          </Close>
        </Header>
      )}
      {children}
      {overlay && <DisplayOverlay onClick={onClose} />}
    </Container>
  );
};

export default Modal;

import { h, FunctionalComponent, Fragment } from "preact";
import { useEffect, useState } from "preact/hooks";
import { styled } from "linaria/react";

import { appRoute } from "util/routerUtil";
import { useAnalytics } from "hooks/analyticsHook";
import mq from "style/breakpoints";
import useUser from "hooks/userHook";
import Colors from "style/colors";
import { FadeIn } from "style/layout";
import Text from "components/shared/Text";
import Icon, { IconTypes } from "components/shared/Icon";
import Modal from "components/layout/Modal";
import MobileDownloadApp from "components/layout/MobileDownloadApp";
import { DisplayOverlay } from "style/layout";
import { TypeTips } from "hooks/onboardingTipHook";
import { useOnboardingTip } from "hooks/onboardingTipHook";
import dayjs from "dayjs";
import { useWindowSize } from "hooks/windowHook";

const Content = styled.div<{
  onboardingTipYou?: boolean | null;
}>`
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ onboardingTipYou }) => (onboardingTipYou ? "none" : "2")};
  ${mq.xs} {
    width: 2rem;
    height: 2rem;
    margin-left: 0.5rem;
  }
`;

const Division = styled.div`
  height: 1px;
  background-color: ${Colors.bgDark3};
  border-radius: 50%;
  margin-left: 24px;
`;

const LoggedOutContent = styled(Content)`
  visibility: hidden;
  display: none;
  button {
    display: none;
  }
  ${mq.minXs} {
    display: flex;
    border: none;
    width: auto;
    visibility: visible;
    button {
      display: flex;
      margin-left: 1rem;
      height: 3rem;
      width: 6.5rem;
      padding: 0;
    }
  }
`;

const Container = styled.div`
  width: 2.9rem;
  height: 2.9rem;
  overflow: hidden;
  border-radius: 50%;
  background-color: ${Colors.bgDark4};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;
  border: 2px solid ${Colors.bgLight2};
  pointer-events: none;

  ${mq.xs} {
    width: 1.3rem;
    height: 1.3rem;
  }
  ${mq.sm} {
    width: 21px !important;
    height: 21px !important;
    border: 2px solid ${Colors.bgGrey0};
  }
  ${mq.minSm} {
    width: 33px !important;
    height: 33px !important;
    border: 3px solid ${Colors.bgLight2} !important;
    pointer-events: inherit;
  }

  > img,
  > div {
    width: 24px !important;
    height: 24px !important;
    border-radius: 50%;
    margin-bottom: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
  }

  > div > span {
    width: 10px;
    :before {
      color: #000000;
      font-size: 10px;
      font-weight: 600;
      display: flex;
    }
  }

  > span,
  span:hover {
    color: ${Colors.txtGrey0};
    transform: rotate(0) !important;
    right: inherit !important;
    top: inherit !important;
    font-size: 12px;
  }
`;

const Menu = styled.div`
  position: absolute;
  z-index: 4;
  bottom: 84px;
  flex-flow: column;
  justify-content: space-between;
  background-color: ${Colors.bgDark4};
  border-radius: 12px;
  right: 1rem;
  border: 1px solid ${Colors.bgDark3};
  width: 100vw;
  max-width: 318px;

  > div:last-child {
    border-radius: 0 0 12px 12px;

    > span:first-child {
      transform: rotate(180deg);
    }
  }

  ${mq.minSm} {
    left: 112px;
    bottom: 10px;
    right: 2rem;
    max-width: max-content;
    min-width: 318px;
  }
  ${FadeIn}
  animation: fadeIn 100ms ease-in backwards;
`;

const Item = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 16px 24px;
  transition: opacity 0.25s ease-in-out, background-color 0.25s ease-in-out;
  color: ${Colors.txtLight};
  display: flex;
  align-items: center;
  :hover {
    background-color: ${Colors.bgDark2};
    opacity: 0.9;
  }
  > span {
    margin-right: 16px;
    color: ${Colors.txtLight} !important;
    font-size: 16px !important;
    line-height: 24px !important;
    display: flex;
    :before {
      color: ${Colors.txtGrey3};
      font-size: 24px;
    }
  }
  > span:first-child {
    img {
      width: 24px !important;
      height: 24px !important;
    }
  }
`;

const MobileLink = styled(Item)<{ isModalVisible: boolean }>`
  width: calc(100% + 2px);
  position: relative;
  left: -1px;
  span:before {
    padding-left: 1px;
    color: ${Colors.txtGrey3};
    font-size: 24px;
    display: flex;
  }
`;

const InfoUserContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 16px 24px;
  cursor: auto;
  border-bottom: 1px solid #333333;

  > div:nth-child(1) {
    width: 58px !important;
    height: 58px !important;
    border: 4px solid ${Colors.bgLight2};
    cursor: auto;

    img {
      width: 44px !important;
      height: 44px !important;
    }
    :hover {
      opacity: 1;
    }
    span:hover {
      color: #8e8e93 !important;
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    h1 {
      font-family: "Neusa Next Pro";
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      color: #ffffff;
      margin-bottom: 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 185px;
    }

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #8e8e93 !important;
      text-align: initial;
    }
  }
`;

interface ProfileMenuProps {
  setMobileLink?: (data: boolean) => void;
  openProfileMenu?: boolean;
}

const ProfileMenu: FunctionalComponent<ProfileMenuProps> = ({
  setMobileLink,
  openProfileMenu,
}: ProfileMenuProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { user, logout } = useUser();
  const { track } = useAnalytics();
  const {
    onboardingTipsViewed,
    onboardingTip,
    setOnboardingTip,
    onboardingTipYou,
    firstSessionStart,
  } = useOnboardingTip();
  const { width } = useWindowSize();

  const handleLogout = async (e) => {
    e.preventDefault();
    typeof window !== "undefined" &&
      localStorage.setItem("notificationPermission", "null");
    track("CTA Tapped", {
      name: "Sign Out",
      source: "Profile Menu",
    });

    typeof window !== "undefined" &&
      localStorage.setItem(`onboardingTipsViewed`, "null");

    track("Signed Out", {
      email: user?.email,
      source: "Profile Menu",
    });

    await logout();
    localStorage.removeItem("userSurvey");
    localStorage.removeItem("deepLink");
  };

  if (!user) {
    return (
      <LoggedOutContent>
        {/*      <Button size="M" text="Sign In" href="/signin" secondary />
        <Button size="M" text="Sign Up" handleClick={goToSignUp} /> */}
      </LoggedOutContent>
    );
  }

  const secureUrl = user?.avatar?.secureUrl;
  const { Profile, LeavePath, FavoriteFilled, ExternalLink, Support, X } =
    IconTypes;

  useEffect(() => {
    if (onboardingTip === TypeTips.NoIndividualTip) {
      setIsVisible(true);
      setOnboardingTip(0);
    } else if ((!onboardingTip || onboardingTip === 0) && openProfileMenu) {
      setIsVisible(true);
    }
  }, [onboardingTip, openProfileMenu]);

  return (
    <Content onboardingTipYou={onboardingTipYou}>
      <div>
        <Container
          onClick={() => {
            if (firstSessionStart && !onboardingTipsViewed()?.you) {
              setOnboardingTip(TypeTips.YouIndividualTip);
            }
          }}
        >
          {isVisible && width > 768 ? (
            <div>
              <Icon name={X} size="M" />
            </div>
          ) : secureUrl ? (
            <img src={secureUrl} alt="Profile Avatar" width="32" height="32" />
          ) : (
            <Icon name={Profile} size="M" />
          )}
        </Container>
      </div>
      {isVisible && (
        <Fragment>
          <Menu>
            <InfoUserContainer>
              <Container>
                {secureUrl ? (
                  <img
                    src={secureUrl}
                    alt="Profile Avatar"
                    width="44"
                    height="44"
                  />
                ) : (
                  <Icon name={Profile} size="M" />
                )}
              </Container>
              <div>
                <h1>{`${user?.firstName} ${user?.lastName}`} </h1>
                <span>
                  Member since {dayjs(user?.createdAt).format("YYYY")}
                </span>
              </div>
            </InfoUserContainer>
            <div></div>
            <Item
              onClick={() => {
                track("CTA Tapped", {
                  name: "Edit Account - Profile",
                  source: "Profile Menu",
                });
                appRoute("/settings");
              }}
            >
              <span>
                <img
                  src="https://optimize.romwod.com/image/upload/v1658949024/app/settings-icon.png"
                  alt="settings icon"
                  width={24}
                  height={24}
                />
              </span>
              <Text size="M">Account</Text>
            </Item>
            <Division />
            <Item onClick={() => appRoute("/favorites")}>
              <Icon name={FavoriteFilled} />
              <Text size="M">Favorites</Text>
            </Item>
            <Division />
            <MobileLink
              onClick={() => {
                setIsVisible(false);
                setIsModalVisible(true);
                setMobileLink && setMobileLink(true);
              }}
              isModalVisible={isModalVisible}
            >
              <Icon name={ExternalLink} />
              <Text size="M">Mobile App</Text>
            </MobileLink>
            <Division />
            <Item
              onClick={() => {
                typeof window !== "undefined" &&
                  window.open("https://roadmap.romwod.com");
              }}
            >
              <Icon name={Support} />
              <Text size="M">Feedback</Text>
            </Item>
            <Division />
            <Item onClick={handleLogout}>
              <Icon name={LeavePath} />
              <Text size="M">Logout</Text>
            </Item>
          </Menu>
          <DisplayOverlay
            onClick={() => {
              setIsVisible(false);
              setIsModalVisible(false);
            }}
          />
        </Fragment>
      )}
      <Modal
        isVisible={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
          setMobileLink && setMobileLink(false);
        }}
        closeIcon
        overlay
        type={"mobileDownloadApp"}
        headerIcon={"M"}
      >
        <MobileDownloadApp show />
      </Modal>
    </Content>
  );
};

export default ProfileMenu;

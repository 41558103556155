import { useAction, useSelector } from "@preact-hooks/unistore";
import { useEffect } from "preact/hooks";
import { AppProps } from "models/appProps";
import { getAppProps } from "services";
import { actions } from "store";

export const useAppProps = (): AppProps => {
  const { appProps } = useSelector("appProps");
  const setAppPropsAct = useAction(actions.setAppProps);
  const setAppPropsAction = (ap: AppProps) => setAppPropsAct(ap);
  useEffect(() => {
    if (!appProps || !Object.keys(appProps)?.length) {
      (async () => {
        try {
          const ap = await getAppProps();
          setAppPropsAction(ap);
        } catch (error) {
          console.error("ERROR", error);
        }
      })();
    }
  }, []);
  return { ...appProps };
};

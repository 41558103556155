/** ROUTES */

const staticBaseURL = "https://static.romwod.com/pwa/";
export const muxPlaybackDomain = "content.pliability.com";
export const imagesURL = `${staticBaseURL}images/`;
export const assetsURL = `${staticBaseURL}assets/`;
export const videoURL = `${process.env.VIDEO_HOST || ""}`;
export const muxDataKey = `${process.env.MUX_DATA_KEY || ""}`;
export const castAppId = `${process.env.CAST_APP_ID || ""}`;
export const brightBackAppId = `${process.env.BRIGHTBACK_APP_ID || ""}`;
export const brightBackKeyMode = `${process.env.BRIGHTBACK_KEY_MODE || "test"}`;
export const pubPath = `${process.env.PUBLIC_PATH || ""}`;
export const pubPathNoTrailingSlash = pubPath
  ? pubPath.substring(0, pubPath.length - 1)
  : "";
export const gaUaId = `${process.env.GA_UA_ID || ""}`;
export const gAuthClientId = `${process.env.GOOGLE_AUTH_CLIENT_ID || ""}`;
export const brazeAppId = `${process.env.BRAZE_APP_ID || ""}`;
export const feedFmToken = `${process.env.FEEDFM_TOKEN || ""}`;
export const feedFmSecret = `${process.env.FEEDFM_SECRET || ""}`;

/** PAGES */
export const itemsPerPage =
  typeof window !== "undefined"
    ? window.document.documentElement.clientHeight > 920
      ? 20
      : 12
    : 12;

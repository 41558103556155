import { API_BASE, APIResponse, callApi } from "./api";
import { WatchHistory } from "models/watchHistory";
import { Dayjs } from "dayjs";

export async function trackVideo(opts: {
  id: number;
  watched: number;
  seconds: number;
}): Promise<APIResponse<void>> {
  try {
    const resp = await callApi(`${API_BASE}/videos/${opts.id}/watch`, {
      method: "PUT",
      body: opts,
    });
    return resp;
  } catch (e) {
    throw e;
  }
}

export async function getUserWatchHistory(
  startDate: Dayjs,
  endDate: Dayjs
): Promise<WatchHistory> {
  try {
    const params = new URLSearchParams();
    const dateFmt = "YYYY-MM-DD";
    params.set("start_date", startDate.format(dateFmt));
    params.set("end_date", endDate.format(dateFmt));
    params.set("exclude_raw", "true");
    const resp = await callApi(
      `${API_BASE}/current_user/watch_history?${params.toString()}`
    );
    // This API doesn't return the default format with data.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { watchHistory, timeByDay } = resp;
    return { watchHistory, timeByDay };
  } catch (e) {
    throw e;
  }
}

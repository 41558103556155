import { useEffect } from "preact/hooks";
import { useAction, useSelector } from "@preact-hooks/unistore";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";

import {
  videoOfTheDayToState,
  currentDateToStr,
  dateToString,
  isDateInWeek,
  wodSlugToDates,
} from "util/scheduleUtil";
import actions from "store/actions";
import { Schedule, ScheduledWorkout } from "models/schedule";
import { getSchedule as _getSchedule } from "services/weeklySchedules";
import { Video } from "models/video";

dayjs.extend(isoWeek);

interface ScheduleHook {
  schedule: Schedule;
  currentWorkout: ScheduledWorkout;
  selectedWeek: string;
}

export function useSchedule(scheduleSlug?: string): ScheduleHook {
  const setScheduleAction = useAction(actions.setSchedule);
  const setWorkoutAction = useAction(actions.setCurrentWorkout);
  const { schedule } = useSelector("schedule");
  const { currentWorkout } = useSelector("currentWorkout");
  const scheduleSlugDates = wodSlugToDates(scheduleSlug);
  const selectedWeek = scheduleSlugDates
    ? dateToString(scheduleSlugDates.start.toDate())
    : currentDateToStr();

  const fetchTodaySchedule = async (): Promise<void> => {
    const { data: schedules } = await _getSchedule(selectedWeek);
    if (schedules?.[0]?.scheduledWorkouts) {
      const { schedule: currentSchedule, currentWorkout } =
        videoOfTheDayToState(schedules);
      setWorkoutAction(currentWorkout);
      setScheduleAction(currentSchedule);
    }
  };

  const fetchSchedule = async (): Promise<void> => {
    const { data: schedules } = await _getSchedule(selectedWeek);
    if (schedules?.[0]?.scheduledWorkouts) {
      const { scheduledWorkouts } = schedules[0];
      const [currentWorkout] = scheduledWorkouts;
      setWorkoutAction(currentWorkout);
      setScheduleAction(schedules[0]);
    }
  };

  const syncScheduleStore = (): void => {
    if (schedule) {
      const { currentWorkout } = videoOfTheDayToState([schedule]);
      setWorkoutAction(currentWorkout);
    }
  };

  useEffect(() => {
    const currentScheduleWeekDate: string | null =
      schedule?.scheduledWorkouts?.[0]?.date ?? null;
    if (
      !schedule ||
      (currentScheduleWeekDate &&
        !isDateInWeek(
          dayjs(currentScheduleWeekDate.substr(0, 10)),
          dayjs(selectedWeek)
        ))
    ) {
      if (isDateInWeek(dayjs(selectedWeek), dayjs())) {
        fetchTodaySchedule();
      } else {
        fetchSchedule();
      }
    } else {
      syncScheduleStore();
    }
  }, [selectedWeek]);

  return { schedule, currentWorkout, selectedWeek };
}

interface VideoOfDayHook {
  videoOfDay: Video | null;
}

let fetchingVod = false;

export function useVideoOfDay(loadVod = true): VideoOfDayHook {
  const setVodAction = useAction(actions.setVideoOfDay);
  const { videoOfDay } = useSelector("vod");
  const { currentWorkout } = useSelector("currentWorkout");
  useEffect(() => {
    if (loadVod && !fetchingVod) {
      if (currentWorkout) {
        setVodAction(currentWorkout.video);
        return;
      }
      (async () => {
        try {
          fetchingVod = true;
          const { data: video } = await videoOfDay(currentDateToStr);
          setVodAction(video);
          fetchingVod = false;
        } catch (error) {
          console.error("ERROR", error);
        }
      })();
    }
  }, [loadVod]);

  return { videoOfDay };
}

import { API_BASE, APIResponse, callApi } from "./api";
import { SearchFiltersGroup } from "models/search";

export const parseSearchFiltersGroups = (
  data: SearchFiltersGroup[] | undefined
): SearchFiltersGroup[] => {
  const renameKey = (object, key, newKey) => {
    const targetKey = object[key];
    delete object[key];
    object[newKey] = targetKey;
  };

  data?.forEach((sfg) => {
    for (const prop in sfg) {
      // Remove unset values keys
      if (
        sfg[prop] === null ||
        (Array.isArray(sfg[prop]) && !sfg[prop].length) ||
        (prop !== "active" && sfg[prop] === false)
      ) {
        delete sfg[prop];
      }

      // Singular name for array keys and change tags_list to tags
      if (Array.isArray(sfg[prop]) && sfg[prop].length) {
        if (prop === "tagList") {
          renameKey(sfg, prop, "tags");
        } else {
          renameKey(sfg, prop, prop.slice(0, -1));
        }
      }
      if (prop.includes("Sort")) {
        sfg[prop] = sfg[prop] ? "desc" : "asc";
      }
    }
  });

  return data || [];
};

export async function getSearchFiltersGroups(): Promise<
  APIResponse<SearchFiltersGroup[]>
> {
  try {
    const resp = await callApi(`${API_BASE}/search_filters_groups`);
    return resp;
  } catch (e) {
    return e;
  }
}

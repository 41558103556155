import { AppState } from "store";
import { Path, UserPath } from "models/path";
import { Schedule, ScheduledWorkout } from "models/schedule";
import { SearchResults } from "models/searchResults";
import { ShopifyProduct, User } from "models/user";
import { Plan } from "models/subscription";
import { Video } from "models/video";
import { RefPartner } from "models/refPartner";
import { Favorite } from "models/favorite";
import {
  Duration,
  TargetArea,
  Exercise,
  Athlete,
  Pose,
  Sport,
  PlaybackToken,
} from "models/videoDetail";
import { SearchFiltersGroup, SearchVideoOptions } from "models/search";
import {
  SubscriptionGroup,
  SubscriptionGroupInvite,
} from "models/subscriptionGroup";
import { AppProps } from "models/appProps";
import { WelcomeModalType } from "models/welcomeModal";
import { Survey } from "models/survey";
interface Actions {
  setUser(state: AppState, user: User): Pick<AppState, "user">;
  logOut(state: AppState): Pick<AppState, "user" | "userPaths" | "favorites">;
  setPlans(state: AppState, plans: Plan[]): Pick<AppState, "plans">;
  setVideoDetails(
    state: AppState,
    videoDetails: {
      durations: Duration[];
      targetAreas: TargetArea[];
      exercises: Exercise[];
      athletes: Athlete[];
    }
  ): Pick<
    AppState,
    | "athletes"
    | "durations"
    | "equipment"
    | "exercises"
    | "poses"
    | "targetAreas"
  >;
  setTargetAreas(
    state: AppState,
    targetAreas: TargetArea[]
  ): Pick<AppState, "targetAreas">;
  setAthletes(state: AppState, athletes: Athlete[]): Pick<AppState, "athletes">;
  setPoses(state: AppState, poses: Pose[]): Pick<AppState, "poses">;
  setSports(state: AppState, sports: Sport[]): Pick<AppState, "sports">;
  setSearchFiltersGroups(
    state: AppState,
    searchFiltersGroups: SearchFiltersGroup[]
  ): Pick<AppState, "searchFiltersGroups">;
  setFavorites(
    state: AppState,
    favorites: Favorite[]
  ): Pick<AppState, "favorites">;
  addFavorite(state: AppState, favorite: Favorite): Pick<AppState, "favorites">;
  removeFavorite(
    state: AppState,
    favorite: Favorite
  ): Pick<AppState, "favorites">;
  setCurrentVideo(
    state: AppState,
    video: Video
  ): Pick<AppState, "currentVideo">;
  setCurrentVideoToken(
    state: AppState,
    videoToken: PlaybackToken
  ): Pick<AppState, "currentVideoToken">;
  setVideoOfDay(state: AppState, vod: Video): Pick<AppState, "vod">;
  setCurrentWorkout(
    state: AppState,
    currentWorkout: ScheduledWorkout
  ): Pick<AppState, "currentWorkout">;
  setSchedule(state: AppState, schedule: Schedule): Pick<AppState, "schedule">;
  setFeaturedPaths(
    state: AppState,
    featuredPaths: Path[]
  ): Pick<AppState, "featuredPaths">;
  setWelcomePath(
    state: AppState,
    welcomePath: Path
  ): Pick<AppState, "welcomePath">;
  setPaths(state: AppState, paths: Path[]): Pick<AppState, "paths">;
  setUserPaths(
    state: AppState,
    userPaths: UserPath[]
  ): Pick<AppState, "userPaths">;
  addPath(state: AppState, userPath: UserPath): Pick<AppState, "userPaths">;
  resetPath(state: AppState, userPath: UserPath): Pick<AppState, "userPaths">;
  leavePath(state: AppState, userPathId: number): Pick<AppState, "userPaths">;
  advancePath(state: AppState, userPath: UserPath): Pick<AppState, "userPaths">;
  startPath(state: AppState, userPathId: number): Pick<AppState, "userPaths">;
  setSearchResults(
    state: AppState,
    searchResults: SearchResults
  ): Pick<AppState, "searchResults">;
  setSearchOpts(
    state: AppState,
    searchSearchOpts: SearchVideoOptions
  ): Pick<AppState, "searchOpts">;
  setAppProps(state: AppState, appProps: AppProps): Pick<AppState, "appProps">;
  setRefPartner(
    state: AppState,
    refPartners: RefPartner
  ): Pick<AppState, "refPartner">;
  setCurrentUrl(
    state: AppState,
    currentUrl: string
  ): Pick<AppState, "currentUrl">;
  setLastFullVideo(
    state: AppState,
    lastFullVideo: string
  ): Pick<AppState, "lastFullVideo">;
  setRoutineEquipment(
    state: AppState,
    routineEquipment: ShopifyProduct[]
  ): Pick<AppState, "routineEquipment">;
  setSubscriptionGroup(state: AppState, subscriptionGroup: SubscriptionGroup);
  setSubscriptionGroupInvites(
    state: AppState,
    subscriptionGroups: SubscriptionGroupInvite[]
  );
  setSearchOptsMembers(
    state: AppState,
    searchOptsMembers: SearchVideoOptions
  ): Pick<AppState, "searchOptsMembers">;
  setSearchResultsMembers(
    state: AppState,
    searchResultsMembers: any
  ): Pick<AppState, "searchResultsMembers">;
  setOnboardingTip(
    state: AppState,
    onBoardingTip: any
  ): Pick<AppState, "onboardingTip">;
  setNativeNotificationAccepted(
    state: AppState,
    nativeNotificationAccepted: boolean
  ): Pick<AppState, "nativeNotificationAccepted">;
  setWelcomeModal(
    state: AppState,
    welcomeModal: WelcomeModalType
  ): Pick<AppState, "welcomeModal">;
  setSurvey(state: AppState, survey: Survey): Pick<AppState, "survey">;
  setContentPreferences(
    state: AppState,
    contentPreferences: Survey
  ): Pick<AppState, "contentPreferences">;
  setPlayingVideo(
    state: AppState,
    playingVideo: boolean
  ): Pick<AppState, "playingVideo">;
}

const actions: Actions = {
  // Actions can just return a state update:
  setUser(_state, user) {
    return { user };
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  logOut(_state) {
    return { user: null, userPaths: null, favorites: null };
  },
  setPlans(_state, plans) {
    return { plans };
  },
  /** VIDEO*/
  setSearchResults(_state, searchResults) {
    return { searchResults };
  },
  setSearchOpts(_state, searchOpts) {
    return { searchOpts };
  },
  /** VIDEO DETAILS */
  setVideoDetails(_state, videoDetails) {
    return { ...videoDetails };
  },
  setTargetAreas(_state, targetAreas) {
    return { targetAreas };
  },
  setAthletes(_state, athletes) {
    return { athletes };
  },
  setPoses(_state, poses) {
    return { poses };
  },
  setSports(_state, sports) {
    return { sports };
  },
  /** SEARCH FILTERS GROUPS */
  setSearchFiltersGroups(_state, searchFiltersGroups) {
    return { searchFiltersGroups };
  },
  /** FAVORITES */
  setFavorites(_state, favorites) {
    return { favorites };
  },
  addFavorite(_state, favorite) {
    const favorites = Array.isArray(_state.favorites)
      ? [..._state.favorites, favorite]
      : [favorite];
    return { favorites };
  },
  removeFavorite(_state, favorite) {
    const favorites =
      _state.favorites?.filter((f) => f.id !== favorite.id) ?? null;
    return { favorites };
  },
  /** SCHEDULE */
  setSchedule(_state, schedule) {
    return { schedule };
  },
  setCurrentWorkout(_state, currentWorkout) {
    return { currentWorkout };
  },
  setVideoOfDay(_state, vod) {
    return { vod };
  },
  setCurrentVideo(_state, video) {
    return { currentVideo: video };
  },
  setCurrentVideoToken(_state, token) {
    return { currentVideoToken: token };
  },
  /** PATHS */
  setFeaturedPaths(_state, featuredPaths) {
    return { featuredPaths };
  },
  setPaths(_state, paths) {
    return { paths };
  },
  setWelcomePath(_state, welcomePath) {
    return { welcomePath };
  },
  setUserPaths(_state, userPaths) {
    return { userPaths };
  },
  addPath(_state, userPath) {
    const userPaths = Array.isArray(_state.userPaths)
      ? [..._state.userPaths, userPath]
      : [userPath];
    return { userPaths };
  },
  resetPath(_state, userPath) {
    const userPaths = Array.isArray(_state.userPaths)
      ? _state.userPaths.map((up) =>
          up.id === userPath.id
            ? {
                ...up,
                currentVideoId: userPath.currentVideoId,
                progressPercentage: userPath.progressPercentage,
                totalCompleted: userPath.totalCompleted,
                updatedAt: userPath.updatedAt,
                started: false,
              }
            : up
        )
      : [userPath];
    return { userPaths };
  },
  leavePath(_state, userPathId) {
    const userPaths = Array.isArray(_state.userPaths)
      ? _state.userPaths.filter((userPath) => userPath.id !== userPathId)
      : null;
    return { userPaths };
  },
  advancePath(_state, userPath) {
    const userPaths = Array.isArray(_state.userPaths)
      ? _state.userPaths.map((up) =>
          up.id === userPath.id
            ? {
                ...up,
                currentVideoId: userPath.currentVideoId,
                progressPercentage: userPath.progressPercentage,
                totalCompleted: userPath.totalCompleted,
                updatedAt: userPath.updatedAt,
              }
            : up
        )
      : [userPath];
    return { userPaths };
  },
  startPath(_state, userPathId) {
    const userPaths = Array.isArray(_state.userPaths)
      ? _state.userPaths.map((up) =>
          up.id === userPathId
            ? {
                ...up,
                started: true,
              }
            : up
        )
      : null;
    return { userPaths };
  },
  setLastFullVideo(_state, lastFullVideo) {
    return { lastFullVideo };
  },
  setCurrentUrl(_state, currentUrl) {
    return { currentUrl };
  },
  setAppProps(_state, appProps) {
    return { appProps };
  },
  setRefPartner(_state, refPartner) {
    return { refPartner };
  },
  setRoutineEquipment(_state, routineEquipment) {
    return { routineEquipment };
  },
  setSubscriptionGroup(_state, subscriptionGroup) {
    return { subscriptionGroup };
  },
  setSubscriptionGroupInvites(_state, subscriptionGroupInvites) {
    return { subscriptionGroupInvites };
  },
  setSearchOptsMembers(_state, searchOptsMembers) {
    return { searchOptsMembers };
  },
  setSearchResultsMembers(_state, searchResultsMembers) {
    return { searchResultsMembers };
  },
  setOnboardingTip(_state, onboardingTip) {
    return { onboardingTip };
  },
  setNativeNotificationAccepted(_state, nativeNotificationAccepted) {
    return { nativeNotificationAccepted };
  },
  setWelcomeModal(_state, welcomeModal) {
    return { welcomeModal };
  },
  setSurvey(_state, survey) {
    return { survey };
  },
  setContentPreferences(_state, contentPreferences) {
    return { contentPreferences };
  },
  setPlayingVideo(_state, playingVideo) {
    return { playingVideo };
  },
};

export default actions;

import { h, FunctionalComponent } from "preact";
import { styled } from "linaria/react";

import mq from "style/breakpoints";
import Colors from "style/colors";
import Text from "components/shared/Text";
import { pubPathNoTrailingSlash } from "config";
import { useWindowSize } from "hooks/windowHook";
import { checkSafariMobile } from "util/userAgentUtil";

const Content = styled.div<{ onlyMobile: boolean; isSafariMobile: boolean }>`
  position: fixed;
  top: ${({ onlyMobile }) => (onlyMobile ? "0" : "3.5rem")};
  right: 0;
  bottom: ${({ onlyMobile }) => (onlyMobile ? "0" : "2.5rem")};
  left: 0;
  width: ${({ onlyMobile }) => (onlyMobile ? "95vw" : "inherit")};
  height: ${({ isSafariMobile }) => (isSafariMobile ? "78vh" : "90vh")};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 3.5rem;
  padding-top: ${({ onlyMobile }) => (onlyMobile ? "1rem" : "0")};
  background-color: ${Colors.bgDark0};
  z-index: 9999999999;
  overflow: hidden;
  ${mq.minXs} {
    width: 32.75rem;
    height: 37.5rem;
    margin: auto;
    position: initial;
    padding: 0 3.5rem 3.5rem;
    right: ${({ onlyMobile }) => (onlyMobile ? "0" : "2.5rem")};
    left: ${({ onlyMobile }) => (onlyMobile ? "0" : "2.5rem")};
  }
  > h1 {
    font-size: ${({ onlyMobile }) => (onlyMobile ? "3rem" : "2rem")};
  }
  & ~ section {
    display: none;
  }
  ${mq.minXs} {
    display: ${({ onlyMobile }) => (onlyMobile ? "none" : "flex")};
    & ~ section {
      display: grid;
    }
  }
  > h2 {
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 2.5rem;
  }
  > span {
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  :after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 50%, black 82.5%);
    top: 0;
    left: 0;
  }
  > img {
    width: 100%;
    height: auto;
  }
`;

const Badge = styled.a`
  cursor: pointer;
`;

const BadgesContainer = styled.div<{
  onlyMobile: boolean;
}>`
  position: absolute;
  top: 85%;
  display: flex;
  align-items: center;
  ${Badge}:first-child {
    margin-right: 1rem;
  }
  ${mq.minXs} {
    bottom: 0.8rem;
  }
`;

const MobileDownloadApp: FunctionalComponent<{
  show: boolean;
  onlyMobile?: boolean;
}> = ({ show, onlyMobile }: { show: boolean; onlyMobile?: boolean }) => {
  const { width } = useWindowSize();
  const isSafariMobile = checkSafariMobile(width);
  if (!show) return null;

  const getDownloadAppUrl = (platform: "ios" | "android") => {
    return platform === "android"
      ? "//play.google.com/store/apps/details?id=com.romwodllc.android"
      : "//apps.apple.com/us/app/romwod/id1175346453";
  };

  return (
    <Content onlyMobile={Boolean(onlyMobile)} isSafariMobile={isSafariMobile}>
      <h1>NOW AVAILABLE ON iOS + ANDROID.</h1>
      <Text size="S">
        For the best experience, download our mobile app. Use all of your
        favorite features including daily content, offline videos, activity,
        paths and more!
      </Text>

      <ImageContainer>
        <img
          src={`${pubPathNoTrailingSlash}/assets/images/iphone_download_app.png`}
          alt="iPhone Download App"
          width={onlyMobile ? "250" : "206"}
          height={onlyMobile ? "500" : "412"}
        />
      </ImageContainer>
      <BadgesContainer onlyMobile={Boolean(onlyMobile)}>
        <Badge
          href={getDownloadAppUrl("ios")}
          target="_blank"
          rel="noreferrer"
          data-testid={"ios"}
        >
          <img
            src={`${pubPathNoTrailingSlash}/assets/images/apple_badge.svg`}
            alt="Apple Badge"
            width="148"
            height="48"
          />
        </Badge>
        <Badge
          href={getDownloadAppUrl("android")}
          target="_blank"
          rel="noreferrer"
          data-testid={"android"}
        >
          <img
            src={`${pubPathNoTrailingSlash}/assets/images/android_badge.svg`}
            alt="Android Badge"
            width={"164"}
            height="58"
          />
        </Badge>
      </BadgesContainer>
    </Content>
  );
};

export default MobileDownloadApp;

import { API_BASE, APIResponse, callApi } from "./api";
import { ShopifyProduct } from "models/user";

export async function getShopifyEquipment(): Promise<
  APIResponse<ShopifyProduct[]>
> {
  try {
    const resp = await callApi(`${API_BASE}/shopify/collections/routine`);
    return resp?.data;
  } catch (e) {
    throw e;
  }
}

interface ShopifySSO {
  shopLoginUrl: string;
}

export async function shopifyLogin(): Promise<ShopifySSO> {
  try {
    const resp = await callApi(`${API_BASE}/shop_login?no_redirect=true`);
    return resp?.data;
  } catch (e) {
    throw e;
  }
}

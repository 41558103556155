import { API_BASE, APIResponse, callApi, ApiTokenResponse } from "./api";
import { Video } from "models/video";
import { searchOptsToQueryString } from "util/SearchUtil";
import { SearchVideoOptions } from "models/search";

export async function searchVideos(
  opts?: SearchVideoOptions
): Promise<APIResponse<Video[]>> {
  try {
    const resp = await callApi(
      `${API_BASE}/videos/search?${searchOptsToQueryString(opts)}`
    );
    return resp;
  } catch (e) {
    return e;
  }
}

export async function videoBySlug(slug: string): Promise<APIResponse<Video>> {
  try {
    const resp = await callApi(`${API_BASE}/videos/by-slug/${slug}`);
    return resp;
  } catch (e) {
    return e;
  }
}

export async function videoByShortcode(
  shortCode: string
): Promise<APIResponse<Video>> {
  try {
    const resp = await callApi(`${API_BASE}/videos/info/${shortCode}`);
    return resp;
  } catch (e) {
    return e;
  }
}

export async function videoToken(
  playbackId: string
): Promise<ApiTokenResponse> {
  try {
    const opts = { playbackId };
    const resp = await callApi(`${API_BASE}/videos/token`, {
      method: "POST",
      body: opts,
    });
    return resp;
  } catch (e) {
    return e;
  }
}

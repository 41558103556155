import { h } from "preact";
import { styled } from "linaria/react";
import Colors from "style/colors";

interface IIconProps {
  name: IconTypes;
  size?: "L" | "M" | "S";
  className?: string;
  color?: string;
}

export enum IconTypes {
  Activity = "Activity",
  Airplay = "Airplay",
  Android = "Android",
  AngleDown = "Angle--Down",
  AngleLeft = "Angle--Left",
  AngleRight = "Angle--Right",
  AngleUp = "Angle--Up",
  Apple = "Apple",
  ArrowDown = "Arrow--Down",
  ArrowLeft = "Arrow--Left",
  ArrowRight = "Arrow--Right",
  ArrowUp = "Arrow--Up",
  CaretDown = "Caret--Down",
  CaretLeft = "Caret--Left",
  CaretRight = "Caret--Right",
  CaretUp = "Caret--Up",
  Cast = "Cast",
  CompletionDone = "Completion--Done",
  CompletionTBD = "Completion--TBD",
  DownloadActive = "Download--Active",
  Download = "Download",
  ErrorAlert = "Error-Alert",
  Expand = "Expand",
  Explore = "Explore",
  ExternalLink = "External-Link",
  EyeOff = "Eye--Off",
  EyeOn = "Eye--On",
  FastForward = "Fast-Forward",
  FavoriteFilled = "Favorite--Filled",
  Favorite = "Favorite",
  FilterFilled = "Filter--Filled",
  Filter = "Filter",
  Help = "Help",
  Home = "Home",
  LeavePath = "Leave-Path",
  Loading = "Loading",
  More = "More",
  Paths = "Paths",
  Pause = "Pause",
  Play = "Play",
  Profile = "Profile",
  ResetProgress = "Reset-Progress",
  Rewind = "Rewind",
  Search = "Search",
  Settings = "Settings",
  Share = "Share",
  Shorter = "Shorter",
  Success = "Success",
  Support = "Support",
  Shop = "Shop",
  TrashFilled = "Trash-2",
  Upload = "Upload",
  Warning = "Warning",
  X = "X",
  ZoomIn = "Zoom-In",
  ZoomOut = "Zoom-Out",
  Subtract = "Subtract",
}

const fontSize = {
  L: "2rem",
  M: "1.5rem",
  S: "1rem",
  XS: "0.75rem",
};

const iconColor = {
  white: "white",
  yellow: "#FFB300",
  black: "black",
};

const Icon = (
  { name, size, className, color }: IIconProps = {
    name: IconTypes.Activity,
    size: "M",
    color: "white",
  }
): JSX.Element | null => {
  const IconSpan = styled.span<IIconProps>`
    font-size: ${({ size }) =>
      fontSize[String(size)] ? fontSize[String(size)] : ""};
    color: ${({ color }) =>
      iconColor[String(color)] ? iconColor[String(color)] : "inherit"};
    :after {
      display: none;
    }
  `;

  const classNames = [`icon-${name}`];

  if (className !== undefined) {
    classNames.push(className);
  }

  const _className = classNames.join(" ");

  return (
    <IconSpan name={name} size={size} color={color} className={_className}>
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
    </IconSpan>
  );
};

export default Icon;

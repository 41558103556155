/* eslint-disable camelcase */
import { useEffect } from "preact/hooks";

import { toSnake, keysToSnake } from "helpers/humps";
import User from "util/userUtil";

export type SegmentEventType =
  | "CTA Tapped"
  | "Page Viewed"
  | "Signed Up"
  | "Account Created"
  | "Signed In"
  | "Signed Out"
  | "Form Submitted"
  | "Subscribed"
  | "Form Submitted Error"
  | "Content Searched"
  | "Content Favorited"
  | "Content Favorited Removed"
  | "Content Started"
  | "Content Resumed"
  | "Content Screen Expand Selected"
  | "Content Fast Forwarded"
  | "Content Rewinded"
  | "Content Stopped"
  | "Content Completed"
  | "Content Casted"
  | "Content Cast Disconnected"
  | "Order Completed"
  | "Modal Shown"
  | "Modal Closed"
  | "Fitness Assessment Content Selected"
  | "Sign Up Started"
  | "Email Entered"
  | "Content Shared"
  | "Path Joined"
  | "Path Completed"
  | "Path Left"
  | "Path Restarted";

export const useAnalytics = () => {
  const user = User.isLoggedIn() && User.hasUserData() && User.userData();
  let analytics;

  useEffect(() => {
    if (typeof window === "undefined" || process.env.NODE_ENV === "test")
      return;

    // Create a queue, but don't obliterate an existing one!
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics = (window as any).analytics = (window as any).analytics || [];

    // If the real analytics.js is already on the page return.
    if (analytics.initialize) return;

    // If the snippet was invoked already show an error.
    if (analytics.invoked) return;

    // Invoked flag, to make sure the snippet
    // is never invoked twice.
    analytics.invoked = true;

    // A list of the methods in Analytics.js to stub.
    analytics.methods = [
      "trackSubmit",
      "trackClick",
      "trackLink",
      "trackForm",
      "pageview",
      "identify",
      "reset",
      "group",
      "track",
      "ready",
      "alias",
      "debug",
      "page",
      "once",
      "off",
      "on",
      "addSourceMiddleware",
      "addIntegrationMiddleware",
      "setAnonymousId",
      "addDestinationMiddleware",
    ];

    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The `method` is
    // stored as the first argument, so we can replay the data.
    analytics.factory = function (method) {
      return function (...rest) {
        const args = Array.prototype.slice.call(rest);
        args.unshift(method);
        analytics.push(args);
        return analytics;
      };
    };

    // For each of our methods, generate a queueing stub.
    for (let i = 0; i < analytics.methods.length; i++) {
      const key = analytics.methods[i];
      analytics[key] = analytics.factory(key);
    }

    // Define a method to load Analytics.js from our CDN,
    // and that will be sure to only ever load it once.
    analytics.load = function (key, options) {
      // Create an async script element based on your key.
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src =
        "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";

      // Insert our script next to the first script element.
      const first = document.getElementsByTagName("script")[0];
      first?.parentNode?.insertBefore(script, first);
      analytics._loadOptions = options;
    };

    // Add a version to keep track of what's in the wild.
    analytics.SNIPPET_VERSION = "4.1.0";

    // Load Analytics.js with your key, which will automatically
    // load the tools you've enabled for your account. Boosh!
    analytics.load(process.env.SEGMENT_API_KEY);
  }, []);

  const track = (event: SegmentEventType, properties: any = {}) => {
    // Common to all tracks
    properties.platform = "Web";
    if (event == "CTA Tapped" && !properties.url) {
      properties.url = window.location.href;
    }
    const userTrack =
      User.isLoggedIn() && User.hasUserData() && User.userData();
    if (userTrack) {
      const { id, firstName, lastName, email, subscription } = userTrack;

      const traits = {
        id,
        firstName,
        lastName,
        trialStartDate: subscription?.trialStartedAt,
        trialEndDate: subscription?.trialEndsAt,
        subscriptionStartDate: subscription?.currentPeriodStartedAt,
        subscriptionPlanName: subscription?.plan?.name,
      };

      if (event == "Subscribed") {
        traits["email"] = email;
      }
      window.analytics?.track(
        event,
        keysToSnake({
          ...properties,
        }),
        keysToSnake({ traits })
      );
    } else {
      window.analytics?.track(event, keysToSnake(properties));
    }
  };

  const page = (event: string, properties = {}) => {
    if (user) properties["userId"] = user.id;
    analytics?.page(toSnake(event), properties);
  };

  const identify = () => {
    const userIdentify =
      User.isLoggedIn() && User.hasUserData() && User.userData();

    if (userIdentify) {
      const { id, firstName, lastName, subscription, source } = userIdentify;
      window.analytics?.identify(userIdentify.id, {
        id,
        firstName,
        lastName,
        source,
        trialStartDate: subscription?.trialStartedAt,
        trialEndDate: subscription?.trialEndsAt,
        subscriptionStartDate: subscription?.currentPeriodStartedAt,
        subscriptionPlanName: subscription?.plan?.name,
      });
    }
  };

  return { track, page, identify };
};

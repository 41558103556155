import { h, FunctionalComponent } from "preact";

import IntercomMessenger from "components/shared/IntercomMessenger";
import BrazeConfiguration from "components/shared/BrazeConfiguration";
import { useCurrentUrl } from "hooks/currentUrlHook";

const ExternalServices: FunctionalComponent = () => {
  const { currentUrl } = useCurrentUrl();
  return currentUrl?.includes("/signup") ? null : (
    <div>
      <IntercomMessenger />
      <BrazeConfiguration />
    </div>
  );
};

export default ExternalServices;

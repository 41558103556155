import { API_BASE, APIResponse, callApi } from "./api";
import { StripePaymentIntent, StripeSetupIntent } from "models/subscription";

export async function confirmPaymentIntent(opts: {
  planId: number;
}): Promise<APIResponse> {
  try {
    const resp = await callApi(`${API_BASE}/payment_intents/confirm`, {
      method: "PUT",
      body: opts,
    });
    return resp.data;
  } catch (e) {
    return e;
  }
}

export async function upsertPaymentIntent(opts: {
  planId: number;
}): Promise<APIResponse<StripePaymentIntent>> {
  try {
    const resp = await callApi(`${API_BASE}/payment_intents`, {
      method: "POST",
      body: opts,
    });
    return resp;
  } catch (e) {
    return e;
  }
}

export async function upsertSetupIntent(): Promise<
  APIResponse<StripeSetupIntent>
> {
  try {
    const resp = await callApi(`${API_BASE}/setup_intents`, {
      method: "POST",
    });
    return resp;
  } catch (e) {
    return e;
  }
}

import { API_BASE, APIResponse, callApi } from "./api";
import { Schedule } from "models/schedule";
import { Video } from "models/video";

export async function getSchedule(
  userDate: string | null = null
): Promise<APIResponse<Schedule[]>> {
  try {
    const resp = await callApi(
      `${API_BASE}/weekly_schedules?archived=false${
        userDate ? `&user_date=${userDate}` : ""
      }`
    );
    return resp;
  } catch (e) {
    return e;
  }
}

export async function getArchivedSchedule(): Promise<APIResponse<Schedule>> {
  try {
    const resp = callApi(`${API_BASE}weekly_schedules?archived=true`);
    return resp;
  } catch (e) {
    return e;
  }
}

export async function getVideoOfDay(
  userDate: string | null = null
): Promise<APIResponse<Video>> {
  try {
    const resp = callApi(
      `${API_BASE}/video_of_day ${userDate ? `?user_date=${userDate}` : ""}`
    );
    return resp;
  } catch (e) {
    return e;
  }
}

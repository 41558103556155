import { API_BASE, APIResponse, callApi } from "./api";
import {
  SubscriptionGroup,
  SubscriptionGroupInvite,
} from "models/subscriptionGroup";

interface UpsertInviteResponse {
  message: string;
  members: {
    data: SubscriptionGroupInvite[];
  };
}
export interface ValidateGroupInviteCodeResponse {
  valid: boolean;
  inviteEmail: string | null;
  organization: SubscriptionGroup | null;
}

export async function getSubscriptionGroup(): Promise<
  APIResponse<SubscriptionGroup>
> {
  try {
    const resp = await callApi(`${API_BASE}/subscription_groups`);
    return resp;
  } catch (e) {
    return e;
  }
}

export async function updateSubscriptionGroup(opts: {
  name: string;
  industry: string;
  location: string;
}): Promise<APIResponse<SubscriptionGroup>> {
  try {
    const resp = await callApi(`${API_BASE}/subscription_groups/update`, {
      method: "PUT",
      body: opts,
    });
    return resp;
  } catch (e) {
    return e;
  }
}

export async function getSubscriptionGroupInvites(): Promise<
  APIResponse<{
    members: {
      data: SubscriptionGroupInvite[];
    };
  }>
> {
  try {
    const resp = await callApi(`${API_BASE}/subscription_groups/members`);
    return resp;
  } catch (e) {
    return e;
  }
}

export async function getMemberListLazyLoading(opts: {
  perPage: number;
  page: number;
}): Promise<any> {
  try {
    const resp = await callApi(
      `${API_BASE}/subscription_groups/members?per_page=${opts.perPage}&page=${opts.page}`
    );
    return resp;
  } catch (e) {
    return e;
  }
}

export async function createSubscriptionGroupInvite(opts: {
  email: string[];
}): Promise<APIResponse<UpsertInviteResponse>> {
  try {
    const resp = await callApi(
      `${API_BASE}/subscription_groups/create-invite`,
      {
        method: "POST",
        body: opts,
      }
    );
    return resp;
  } catch (e) {
    return e;
  }
}

export async function deleteSubscriptionGroupInvite(opts: {
  inviteId: number;
}): Promise<APIResponse<UpsertInviteResponse>> {
  try {
    const resp = await callApi(
      `${API_BASE}/subscription_groups/delete-member/${opts.inviteId}`,
      {
        method: "DELETE",
        body: opts,
      }
    );
    return resp;
  } catch (e) {
    return e;
  }
}

export async function resendSubscriptionGroupInvite(opts: {
  inviteId: number;
}): Promise<APIResponse<UpsertInviteResponse>> {
  try {
    const resp = await callApi(
      `${API_BASE}/subscription_groups/resend-invite/${opts.inviteId}`,
      {
        method: "POST",
        body: opts,
      }
    );
    return resp;
  } catch (e) {
    return e;
  }
}

export async function validateGroupInviteCode(
  inviteCode: string
): Promise<APIResponse<ValidateGroupInviteCodeResponse>> {
  try {
    const resp = await callApi(
      `${API_BASE}/subscription_groups/validate?invite_code=${inviteCode}`
    );
    return resp;
  } catch (e) {
    return e;
  }
}
